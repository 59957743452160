.resume-main-block {
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px;
  position: relative;
  margin-top: -100px;
}

.resume-section-title {
  color: var(--large-heading-color);
  align-self: center;
  padding-top: clamp(8px, 4vw, 16px);
}

.resume-cards-container {
  display: flex;
  flex-direction: column;
  border: 1px solid hsl(210, 23%, 95%);
  box-shadow: var(--box-shadow);
  padding: clamp(16px, 4vw, 32px);
  padding-top: calc(clamp(16px, 4vw, 32px) - clamp(8px, 4vw, 16px));
  max-width: 1024px;
  display: flex;
  gap: 24px;
  background-color: var(--resume-background);
  box-sizing: border-box;
}

.resume-card {
  display: flex;
  gap: clamp(12px, 4vw, 24px);
}

.resume-card-image-container {
  width: clamp(36px, 6vw, 60px);
  height: clamp(36px, 6vw, 60px);
  max-width: clamp(36px, 6vw, 60px);
  max-height: clamp(36px, 6vw, 60px);
  min-width: 0px;
  min-height: 0px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.resume-card-image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border-width: 2px;
  box-sizing: border-box;
  border-top: 1px solid #ffffff;
}

.resume-card-image-container::before,
.project-card-image-container::before {
  box-shadow: inset 0 0px 8px hsla(0, 0%, 0%, 0.1);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 50%;
}

.resume-card-header,
.project-card-header {
  color: var(--large-heading-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  font-size: clamp(16px, 3vw, 24px);
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 4px;
  letter-spacing: -0.0125rem;
}

.resume-card-header-main {
}

.resume-subtitle,
.project-subtitle {
  margin-bottom: 12px;
  font-weight: 300;
  color: var(--sub-text-color);
  font-size: clamp(12px, 3vw, 14px);
}

.resume-line {
  margin-bottom: 4px;
  color: var(--sub-text-color);
  font-size: clamp(12px, 3vw, 16px);
  letter-spacing: -0.025rem;
  font-weight: 400;
  /* line-height: 1.125rem; */
}

.project-section-title {
  color: var(--large-heading-color);
  align-self: center;
  padding-top: 120px;
  margin-bottom: clamp(16px, 4vw, 32px);
  font-size: 96px;
  letter-spacing: -0.75rem;
  text-transform: uppercase;
  font-weight: 400;
  filter: blur(4px);
  font-style: italic;
  transform: scale(2, 1);
}

.project-cards-container {
  max-width: min(1600px, 100%);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(384px, 1fr));
  gap: 24px;
}
.project-card {
  padding: clamp(32px, 4vw, 64px);
  background-color: var(--resume-background);
  transition: 0.4s;
  display: flex;
  justify-content: space-between;
  gap: clamp(32px, 4vw, 64px);
  border: 1px solid hsl(210, 23%, 95%);
  box-shadow: var(--box-shadow);
  align-items: center;
  cursor: pointer;
}

.project-card:hover {
  transform: translateY(-12px);
  filter: var(--drop-shadow);
}

.project-card-image-container {
  height: 96px;
  width: 96px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.project-card-image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border-width: 2px;
  box-sizing: border-box;
  border-top: 1px solid #ffffff;
}

/* Fixing animation when changing  width */
@media screen and (max-width: 1054px) {
  .resume-main-block {
    position: unset;
    margin-top: 32px;
  }
}

@media screen and (max-width: 899px) {
  .project-section-title {
    font-size: 64px;
    letter-spacing: -0.5rem;
    filter: blur(3px);
  }
}

@media screen and (max-width: 599px) {
  .project-section-title {
    font-size: 32px;
    letter-spacing: -0.2rem;
    filter: blur(2px);
  }
  .project-cards-container {
    grid-template-columns: auto;
  }
  .resume-line {
    letter-spacing: -0.0125rem;
  }
}
