:root {
  --linkedin-color: #007bb5;
  --github-color: #24292d;
  --instagram-color: #f00075;
  --twitter-color: #55acee;
  --spotify-color: #1bd760;
  --email-color: #555;
  --header-height: calc(24px + 24px + 32px);
  --border-style: 2px solid grey;
  /* Light mode themes */
  --small-heading-color: #666;
  --large-heading-color: black;
  --border-style: 2px solid grey;
  --background-dark: black;
  --accent-color: rgb(33, 150, 243);
  --accent-color-light: hsla(202, 100%, 85%, 1);
  --accent-highlight: #ddf2ff;
  --background-color: hsl(32 84% 98%);
  --background-overlay: var(--background-color);
  --header-background: hsl(50, 10%, 90%, 50%);
  --cover-background: linear-gradient(81deg, rgba(191, 191, 191, 1), rgba(255, 219, 177, 1));
  --resume-background: white;
  --title-text-color: #fcb2ac;
  --text-color: black;
  --sub-text-color: rgba(0, 0, 0, 0.875);
  --divider-color: rgba(0, 0, 0, 0.12);
  --box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12);
  --drop-shadow: drop-shadow(0px 20px 24px rgba(149, 157, 165, 0.4));

  /* Other */
  --background-dark: #101010;
}
/* Dark mode themes */

body.dark-mode {
  --small-heading-color: #666;
  --large-heading-color: white;
  --background-color: hsl(259, 15%, 12%);
  /* --background-overlay: #212121; */
  --background-overlay: var(--background-color);
  --header-background: hsl(255, 50%, 50%, 25%);
  --cover-background: linear-gradient(45deg, rgba(41, 41, 41, 1) 50%, rgba(71, 0, 203, 1));
  --resume-background: hsl(255, 5%, 15%);
  --title-text-color: #fcb2ac;
  --text-color: white;
  --sub-text-color: rgba(255, 255, 255, 0.875);
  --divider-color: var(--small-heading-color);
  --accent-color: #f85846;
  --accent-color-light: hsla(350, 100%, 85%, 1);
  --accent-highlight: #ffe5db;
  /* --box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12), 0px 3px 12px rgba(255, 255, 255, 0.3); */
  --drop-shadow: drop-shadow(0px 3px 12px rgba(255, 255, 255, 0.2));
}
