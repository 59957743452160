.icon-container {
  display: grid;
  /* container width + padding * 2 = 45 */
  grid-template-columns: repeat(auto-fit, 45px);
  padding: 8px 0px;
  column-gap: 8px;
  row-gap: 8px;
}
/* LinkedIn */
.fa-linkedin,
.linkedin {
  background: var(--linkedin-color);
  color: white;
  font-size: 25px;
}

/* GitHub */
.fa-github,
.github {
  background: var(--github-color);
  color: white;
  font-size: 25px;
}

/* Twitter */
.fa-twitter,
.twitter {
  background: var(--twitter-color);
  color: white;
  font-size: 25px;
}
/* Instagram */
.fa-instagram,
.instagram {
  background: var(--instagram-color);
  color: white;
  font-size: 25px;
}

/* Spotify */
.fa-spotify,
.spotify {
  background: var(--spotify-color);
  color: white;
  font-size: 25px;
}

/* Email */
.fa-envelope,
.envelope {
  background: var(--email-color);
  color: white;
  font-size: 25px;
}

.social-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
}
.social-icon-link:hover .social-container,
.social-icon-link:focus .social-container {
  transform: scale(1.1);
}
