:focus {
  outline: none;
}
.app-fade {
  animation: appfade 0.5s ease-in;
}
@keyframes appfade {
  0% {
    opacity: 0;
  }
}
.notReadyToLoad {
  opacity: 0;
}
.App {
  height: 100vh;
}
.header {
  position: fixed;
  top: 0;
  z-index: 1; /* This fixes animated components overlapping header */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body {
}
.footer {
}
