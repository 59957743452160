.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  padding-left: clamp(16px, 5vw, 256px);
  padding-right: clamp(16px, 5vw, 256px);
  width: 100%;
  margin: 24px clamp(16px, 15vw, 256px);
  backdrop-filter: blur(10px) saturate(1.7);
  background-color: var(--header-background);
  border-radius: 50px;
}
.links-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-heading-link {
  text-decoration: none;
  color: var(--large-heading-color);
  -webkit-user-select: text; /* Chrome all / Safari all */
  -moz-user-select: text; /* Firefox all */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Likely future */
}
.main-heading {
  color: var(--large-heading-color);
  font-size: 24px;
  margin: 0px;
  position: relative;
  font-weight: 900;
  letter-spacing: 0.075rem;
}
.main-heading::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: var(--accent-color);
  display: block;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transition: transform 150ms ease-in-out;
  transition: -webkit-transform 150ms ease-in-out;
}
.main-heading-link:focus .main-heading::after,
.main-heading-link:hover .main-heading::after {
  transform: scaleX(1);
}

@media screen and (max-width: 799px) {
  .links-container {
    display: none;
  }
}
