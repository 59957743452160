.header-links-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: clamp(12px, 6vw, 48px);
  row-gap: 0.4em;
}
.header-link {
  margin-bottom: 0px;
  font-size: 16px;
  color: var(--large-heading-color);
  margin-top: 0px;
  position: relative;
  letter-spacing: 0.05rem;
  font-weight: 500;
}
.header-link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  /* background-color: var(--background-dark); */
  background-color: var(--accent-color);
  display: block;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transition: transform 150ms ease-in-out;
  transition: -webkit-transform 150ms ease-in-out;
}
.header-link-container {
  text-decoration: none;
}
.header-link-container:hover .header-link::after,
.header-link-container:focus .header-link::after,
.active-link .header-link::after {
  transform: scaleX(1);
}
